var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "btn-top-primary rounded",
    attrs: {
      "fixed": "",
      "large": "",
      "icon": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'v-btn', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('div', {
    staticClass: "line-height-0"
  }, [_c('icon-arrow-primary', {
    attrs: {
      "size": "xx-small",
      "direction": "up"
    }
  }), _c('div', {
    staticClass: "font-size-12 mt-2px line-height-1"
  }, [_vm._v("TOP")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }