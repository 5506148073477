<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->

        <btn-top-primary />
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import BtnTopPrimary from '@/components/publish/parents/buttons/btn-top-primary.vue';
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        MainFooter,
        BtnTopPrimary,
    },
    mounted() {
        window.addEventListener("copy", this.preventCopy);
        window.addEventListener("contextmenu", this.preventCopy);
        window.addEventListener("selectstart", this.preventDefault);
        window.addEventListener("dragstart", this.preventDefault);
        this.init();

        // 전체메뉴 초기화
        var HTML = document.querySelector('html');
        var header = document.querySelector('.header');
        header.classList.remove('pc-menu-on');
        HTML.classList.remove('pc-menu-hidden');
    },
    destroyed() {
        window.removeEventListener("copy", this.preventCopy);
        window.removeEventListener("contextmenu", this.preventCopy);
        window.removeEventListener("selectstart", this.preventDefault);
        window.removeEventListener("dragstart", this.preventDefault);
    },
	methods : {
		init : function() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 240,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
};
</script>
