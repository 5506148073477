<template>
    <icon-chevron v-bind="$attrs" />
</template>

<script>
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
export default {
    props: {},
    components: {
        IconChevron,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
