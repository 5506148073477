var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__top py-12px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', _vm._l(_vm.link, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "to": item.link,
        "small": "",
        "text": "",
        "color": "grey lighten-1"
      }
    }, [_vm._v(_vm._s(item.title))])], 1);
  }), 1)], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "footer__bottom py-30px py-lg-48px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-140px w-lg-160px",
    attrs: {
      "src": "/images/logo.svg",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "mt-14px mt-md-0px",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text"
    }, [_c('span', {
      staticClass: "grey--text text--darken-1"
    }, [_vm._v(_vm._s(item.title))]), _vm._v(" " + _vm._s(item.info) + " ")])]);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }