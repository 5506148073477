var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed header--menu-all"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("사이트명")])])]), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__contents-wrap"
  }, [_c('div', {
    staticClass: "header__gnb d-block d-xl-flex justify-center"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1)])]), _c('div', {
    staticClass: "header__right"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "lang-wrap"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-earth")])], 1), _c('ul', {
    staticClass: "lang"
  }, [_c('li', {
    staticClass: "on"
  }, [_vm._v("KOR")]), _c('li', [_c('router-link', {
    attrs: {
      "to": "/en"
    }
  }, [_vm._v("ENG")])], 1)])], 1)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--pc"
  })], 1)], 1)], 1)])], 1), _c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--mobile"
  }), _c('div', {
    staticClass: "header__bg"
  }), _c('div', {
    staticClass: "side-gnb-wrap"
  }, [_c('v-container', [_c('client-gnb', {
    attrs: {
      "className": "slide-gnb"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }