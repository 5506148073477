<template>
    <footer class="footer">
        <div class="footer__top py-12px">
            <v-container class="container--lg">
                <v-row>
                    <v-col v-for="item in link" :key="item.title" cols="auto">
                        <v-btn :to="item.link" small text color="grey lighten-1">{{item.title}}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-divider />
        <div class="footer__bottom py-30px py-lg-48px">
            <v-container class="container--lg">
                <v-row align="center" class="row--xl">
                    <v-col cols="12" md="auto">
                        <v-img src="/images/logo.svg" contain class="w-140px w-lg-160px" />
                    </v-col>
                    <v-col cols="12" md="" class="mt-14px mt-md-0px">
                        <v-row class="row--sm">
                            <v-col v-for="item in info" :key="item.title" cols="auto">
                                <p class="page-text">
                                    <span class="grey--text text--darken-1">{{item.title}}</span> {{item.info}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </footer>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            link : [
                { title : "회사소개", link : "", },
                { title : "자주묻는질문", link : "", },
                { title : "개인정보처리방침", link : "", },
                { title : "이용약관", link : "", },
                { title : "공지사항", link : "", },
            ],
            info : [
                { title : "회사명", info : "사이트명", },
                { title : "주소", info : "서울특별시 구로구 디지털로29길 38 에이스테크노타워3차 707호", },
                { title : "대표자명", info : "홍길동", },
                { title : "사업자등록번호", info : "00-0000-0000", },
                { title : "이메일", info : "email@gmail.com", },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.footer {
    border-top: 1px solid var(--border-color);
    color: var(--v-grey-lighten1);
}
::v-deep{
    .v-btn.v-size--small:not(.v-btn--icon) {
        margin: 0 -12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-btn.v-size--small:not(.v-btn--icon) {
            margin: 0 -16px;
        }
    }
}
@media (min-width:1200px){
}

</style>
